import Vue from 'vue';
import Router from 'vue-router';
import store from "./store/store";

Vue.use(Router);

const DEFAULT_TITLE = 'Distribune';

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    // https://router.vuejs.org/ru/guide/advanced/scroll-behavior.html#%D0%B0%D1%81%D0%B8%D0%BD%D1%85%D1%80%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F-%D0%BF%D1%80%D0%BE%D0%BA%D1%80%D1%83%D1%82%D0%BA%D0%B0
    if (to.hash) { // Эмулируем переход к якорю. Тоесть если в ссылку добавить в конце хеш '#my-custom-cool-id', то страница автоматически прокрутится к этому элементу
      return {
        selector: to.hash
      }
    }

    if (to.matched.some(m => m.meta.scrollToTop)) {
      //Если в роуте указать свойство scrollToTop, то savedPosition будет проигнорирован. Как это работает https://router.vuejs.org/ru/guide/advanced/meta.html
      let yPos = window.scrollY > 0 ? 30 : 0; // проверяем каким было положение скролла до смены роута. Значение 30px устанавливается , чтобы хедер показался без верхнего субхедера и не было анимации скачка
      return {x:0, y: yPos}
    }

    return savedPosition; // Возвращаем требуемую позицию прокрутки для конкретной страницы (работает только с pop-state переходами, то есть стрелочками в браузере )

  },
  routes: [{
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ './views/NotFoundScreen.vue')
  },{
      path: '/',
      name: 'home',
      meta: {
          title: 'Home',
      },
      component: () => import(/* webpackChunkName: "home-view" */ './views/HomeScreen.vue'),
  },{
      path: store.state.static.routePaths.webmasters, // -> /webmasters
      name: 'toWebmasters',
      meta: {
          title: 'Webmasters',
      },
      component: () => import(/* webpackChunkName: "webmasters-page" */ './views/WebmastersScreen.vue'),
  },{
      path: store.state.static.routePaths.contentOwners, // -> /content-owners
      name: 'toContentOwners',
      meta: {
          title: 'Content owners',
      },
      component: () => import(/* webpackChunkName: "content-owners-page" */ './views/ContentOwnersScreen.vue'),
  },{
      path: store.state.static.routePaths.bloggers, // -> /bloggers
      name: 'toBloggers',
      meta: {
          routeStyle: 'gray-background',
          title: 'Bloggers',
      },
      component: () => import(/* webpackChunkName: "bloggers-page" */ './views/BloggersScreen.vue'),
  },{
      path: store.state.static.routePaths.aboutUs, // -> /about-us
      name: 'aboutUs',
      meta: {
          routeStyle: 'gray-background',
          title: 'About us',
      },
      component: () => import(/* webpackChunkName: "about-page" */ './views/AboutUsScreen.vue'),
  },
  //     {
  //     path: store.state.static.routePaths.login, // -> /login
  //     name: 'login',
  //     meta: {
  //         title: 'Login',
  //         routeAnimation: 'route-change',
  //     },
  //     beforeEnter(to, from, next){
  //         store.state.user ? next({name: 'statistics',}) : next();
  //     },
  //     component: () => import(/* webpackChunkName: "login-page" */ './views/LoginScreen.vue'),
  // },
      {
      path: `${store.state.static.routePaths.registrationOld}(|/)(|blogger|webmaster)`, // -> /registration
      name: 'registrationOld',
      meta: {
          title: 'Registration',
          routeAnimation: 'route-change',
      },
      component: () => import(/* webpackChunkName: "registration-page" */ './views/RegistrationScreenOld.vue'),
  },
  //     {
  //     path: `${store.state.static.routePaths.registration}(|/)(|blogger|webmaster)`, // -> /registration
  //     name: 'registration',
  //     meta: {
  //         title: 'Registration',
  //         routeAnimation: 'route-change',
  //     },
  //     component: () => import(/* webpackChunkName: "registration-page" */ './views/RegistrationScreen.vue'),
  // },
      {
      path: store.state.static.routePaths.registrationActivate,
      name: 'registrationActivate',
      meta: {
          title: 'Registration',
          routeAnimation: 'route-change',
      },
      component: () => import(/* webpackChunkName: "registration-page" */ './views/RegistrationActivateScreen.vue'),
  },{
      path: store.state.static.routePaths.passRestore, // -> /password-restore
      component: () => import(/* webpackChunkName: "password-restore-page" */ './views/PasswordRestoreScreen.vue'),
      meta: {
          routeAnimation: 'route-change',
      },
      children: [
          {
              path: '',
              name: 'passRestore',
              meta: {
                  title: 'Restore password',
              },
              component: () => import(/* webpackChunkName: "password-restore-page" */ './components/authorisation/PasswordRestoreComponent.vue')
          },
          {
              name: 'passRestoreConfirm',
              path: ':token',
              meta: {
                  title: 'Restore password',
              },
              component: () => import(/* webpackChunkName: "password-restore-page" */ './components/authorisation/SetNewPasswordComponent.vue')
          },
      ]
  },{
      path: store.state.static.routePaths.dashboard, // -> /dashboard
      beforeEnter(to, from, next){
          store.state.user ? next() : next({name: 'login',});
      },
      meta: {
          routeAnimation: 'route-change',
      },
      children: [{
          path: '',
          name: 'dashboard',
          redirect: store.state.static.routePaths.statistics,
          component: () => import(/* webpackChunkName: "dashboard-page" */ './components/dashboard/statistics/StatisticsComponent.vue')
        },{
          path: store.state.static.routePaths.statistics,
          name: 'statistics',
          meta: {
              title: 'Dashboard / statistics',
          },
          component: () => import(/* webpackChunkName: "dashboard-page" */ './components/dashboard/statistics/StatisticsComponent.vue')
        },{
          path: store.state.static.routePaths.videos,
          name: 'videos',
          meta: {
              title: 'Dashboard / videos',
          },
          component: () => import(/* webpackChunkName: "dashboard-page" */ './components/dashboard/videos/VideosComponent.vue')
      },
      ],
      component: () => import(/* webpackChunkName: "dashboard-page" */ './views/DashboardScreen.vue'),
  },{
    path: store.state.static.routePaths.dashboardp, // -> /dashboardp
    meta:{
      routeStyle: 'gray-background'
    },
    beforeEnter(to, from, next){
      store.state.user ? next() : next({name: 'login',});
    },
    children: [{
      path: '',
      name: 'dashboardp',
      redirect: store.state.static.routePaths.statistics,
      component: () => import(/* webpackChunkName: "dashboard-page" */ './components/p/statistics/StatisticsComponent.vue')
    },{
      path: store.state.static.routePaths.statistics,
      name: 'statistics',
      component: () => import(/* webpackChunkName: "dashboard-page" */ './components/p/statistics/StatisticsComponent.vue')
    },{
      path: store.state.static.routePaths.videos,
      name: 'videos',
      component: () => import(/* webpackChunkName: "dashboard-page" */ './components/p/videos/VideosComponent')
    },{
      path: store.state.static.routePaths.articles,
      name: 'articles',
      component: () => import(/* webpackChunkName: "dashboard-page" */ './components/p/articles/ArticlesComponent')
    },
    ],
    component: () => import(/* webpackChunkName: "dashboard-page" */ './views/PubDashboardScreen.vue'),
  },{
      path: store.state.static.routePaths.addVideo,
      name: 'addVideo',
      beforeEnter(to, from, next){
          store.state.user ? next() : next({name: 'login',});
      },
      meta: {
          title: 'Add new video',
          routeAnimation: 'route-change',
      },
      component: () => import(/* webpackChunkName: "add-new-video-page" */ './components/video-add/AddNewVideoComponent.vue'),
  },{
      path: store.state.static.routePaths.documents,
      name: 'documents',
      meta: {
          title: 'Documents',
      },
      children: [
          {
              path: store.state.static.routePaths.legalDocuments,
              name: 'Legal documents',
              meta: {
                  title: 'Documents / Legal documents',
              },
              component: () => import(/* webpackChunkName: "documents-page" */ './components/documents/LegalDocumentsComponent.vue')
          },
          {
              path: store.state.static.routePaths.privacyPolicy,
              name: 'Privacy policy',
              meta: {
                  title: 'Documents / Privacy policy',
              },
              component: () => import(/* webpackChunkName: "documents-page" */ './components/documents/PrivacyPolicyComponent.vue')
          },
          {
              path: store.state.static.routePaths.agreement,
              name: 'Agreement',
              meta: {
                  title: 'Documents / Agreement',
              },
              component: () => import(/* webpackChunkName: "documents-page" */ './components/documents/AgreementComponent.vue')
          },
      ],
      component: () => import(/* webpackChunkName: "documents-page" */ './views/DocumentsScreen.vue'),
  },
  ]
});

router.afterEach((to) => {
    document.title = to.meta.title ? `${to.meta.title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;
});

export default router;

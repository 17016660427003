export default function () {
  return {
      sitesList: [],
      currentWebsiteId: null,
      cached: {
          statisticDataCache: {}
      },
      uploadProgressPercent: 0,
      axiosCancel: null,
      statistics: {
        filters:{
          limit: 10,
          page: 0,
          website_id: null,
          start_date: new Date().adjustDate(-6),
          end_date: new Date(),
          videos: [],
          articles: [],
          group: 'date',
          sortingState:{
            video: 'asc',
            date: 'desc',
            article: 'desc',
          },
        },
        videoHintList: [],
        articleHintList: [],
        videoFiltersList: [],
        articlesFiltersList: [],
        statisticData: [],
        totalStatisticData: [],
      },
      videos: {
          list: [],
          languages: [],
          currentLang: null,
          categories: [],
          keywords: [],
      },
      articles: {
          filters: {
              website_id: null,
              limit: 10,
              title: '',
              page: 0
          },
        articlesData: [],
      }
  }
}

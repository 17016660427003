<template>
  <div id="root" :class="routeStyle">
    <modals-container/>
    <transition :name="routeAnimation">
      <router-view class="main-content"></router-view>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'app',
  data(){
    return {
      routeStyle: '', // Для управления стилем конкретных страниц, например бекграундом до загрузки js файла конкретной вьюхи.
      routeAnimation: '',
    }
  },
  methods: {
    refreshAccessToken(){
      if (!this.$store.state.user) return;
      this.$store.dispatch('REFRESH_ACCESS_TOKEN');
    },
    scrollToTop() {
      window.scroll(0,0);
    }
  },
  updated() {
    this.scrollToTop();
  },
  mounted() {
    this.scrollToTop();
  },
  cron: {
    time: 1000 * 60 * 60, // 1h
    method: 'refreshAccessToken'
  },
  watch: {
    $route (to){
      this.routeStyle = to.matched[0].meta.routeStyle;
      this.routeAnimation = to.matched[0].meta.routeAnimation;
    }
  },
}
</script>

<style lang="scss">
  #root {
    min-height: 100vh;
    position: relative;

    .main-content {
      min-height: 100vh;
    }
  }
  .flex-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .flex-center, .flex-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .flex-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .flex-between, .flex-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .flex-column {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
  }
</style>

import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import ru from '../lang/ru';
import en from '../lang/en';

export default new VueI18n({
    locale: localStorage.getItem('curLang') || 'ru',
    fallbackLocale: 'ru',
    messages: {
        ru,
        en
    },
    silentTranslationWarn: true,
})
import Vue from 'vue';

export default (()=>{
  Vue.directive('scroll', { //Самописная директива v-scroll которая вешает на елмент обработчик скролла окна
    inserted (el, binding) {
      var f = function (evt) {
        if (!document.body.contains(el)) {
          window.removeEventListener('scroll', f);
          return
        }
        binding.value(evt, el)
      };

      window.addEventListener('scroll', f)
    }
  });
  Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
        // here I check that click was outside the el and his childrens
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  });
})()

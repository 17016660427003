import axios from 'axios';
import authApi from './models/auth-model.js';
import statisticsApi from './models/statistics-model.js';
import videosApi from './models/videos-api.js';
import publisherApi from './models/publisher-model';
import articleModel from './models/article-model';

import store from "../store/store";
import router from "../router";

axios.interceptors.response.use(null, function (error) {
  if (error.response && error.response.status) {
    if (error.response.status === 401) {
      store.commit('SET_AUTH_INFO', null); // Если ответ пришел с ошибкой, значит авторизационный ключ устарел и авторизационные данные в хранилище надо обнулить

      if(store.state.user){ // КОСТЫЛЬ! Если нету инфы о пользователе и приходит такая ошибка - значит это авторизационный зпрос
        store.commit('UPDATE_USER_INFO', null);
        router.push(store.state.static.routePaths.login);
      }
    }
  }

  return Promise.reject(error);
});

export default {
  authApi,
  statisticsApi,
  videosApi,
  publisherApi,
  articleModel,
};

<template>
  <div class="loader">
    <slot>
      <div class="sk-fading-circle" :style="`height:${getHeight};width:${getWidth};`">
        <div class="sk-circle" :class="'sk-circle-' + n" v-for="n in 10" :key="n" >
          <div class="sk-circle-before" :style="`background-color:${color}`"></div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'Loader',
    props: ['height', 'width', 'color'],
    computed: {
      getHeight(){
        if (typeof this.height === 'string') return this.height;
        return this.height + 'px'
      },
      getWidth(){
        if (typeof this.width === 'string') return this.width;
        return this.width + 'px'
      },
    }
  };
</script>

<style lang="scss" scoped>
  $spinkit-size: 2em !default;
  $spinkit-spinner-color: #fff !default;

  .loader {
    height: 100%;
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .mdl-spinner {
      width: 35px; //28px
      height: 35px; //28px
    }
  }
  .sk-fading-circle {
    $circleCount: 12;
    $animationDuration: 1.2s;

    width: $spinkit-size;
    height: $spinkit-size;
    position: relative;
    margin: auto;

    .sk-circle {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .sk-circle-before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: $spinkit-spinner-color;
      border-radius: 100%;
      animation: sk-fading-circle-delay $animationDuration infinite ease-in-out both;
    }

    @for $i from 2 through $circleCount {
      .sk-circle-#{$i} {
        transform: rotate((360deg / $circleCount * ($i - 1)));
      }
    }

    @for $i from 2 through $circleCount {
      .sk-circle-#{$i} .sk-circle-before {
        animation-delay: (-$animationDuration + $animationDuration / $circleCount * ($i - 1));
      }
    }

  }

  @keyframes sk-fading-circle-delay {
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
</style>

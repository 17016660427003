import request from './request';

export default {
    createNewArticle: function( params) {
        return request(`api/publisher/create-article`, params || {}, 'post')
            .then((res)=> {
                return res
            })
    },
    getArticlesList: function(query) {
        return request(`api/publisher/articles?${query}`,  {}, 'get')
            .then((res)=> {
                return res
            })
    },
};

import Vue from 'vue';
import './utils/plugins'; //Инициализация всех подключаемях модулей и расширение компонента vue, глобальными методами
import i18n from './utils/i18n';//Добавление мультиязычности на сайте
import router from "./router";
import store from "./store/store";

import './assets/css/common.scss';
import './utils/filters';//Кастомные фильтры
import './utils/directives';//Кастомные директивы
import './utils/baseComponents';//Добавление базовых компонентов (которые начинаются на Base*) в глобальную область видимости

import App from './App.vue';

Vue.config.productionTip = false;

let authInfo = Vue.$storage.get('auth_info');

if (authInfo && new Date(authInfo.expires_at) >= new Date()) { //если авторизационный ключ записан в хранилище - проверим не истек ли его строк годности
    store.dispatch('REFRESH_ACCESS_TOKEN')
        .then(()=>{
            if (window.location.href.indexOf('dashboardp') > -1) {
                store.dispatch('GET_USER_PUBLISHER_INFO')
                    .then(() => {
                        buildUI();
                    })
                    .catch(() => {
                        buildUI();
                    });

            } else {
                store.dispatch('GET_USER_INFO')
                    .then(() => {
                        buildUI();
                    })
                    .catch(() => {
                        buildUI();
                    });
            }
        })
        .catch(() => {
            buildUI();
        });
} else {
    buildUI()
}



function buildUI () {
    window.vm = new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
  }).$mount('#app');
}


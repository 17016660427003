import request from './request';

export default {
    getWebsitesList: function( params) {
        return request('api/publisher/website', params || {}, 'get')
            .then((res)=> {
                return res
            })
    },
    getVASTData: function( params) {
        return request('api/publisher/vast', params || {}, 'post')
            .then((res)=> {
                return res
            })
    },
    getCode: function( params) {
        return request(`api/publisher/code`, params || {}, 'post')
            .then((res)=> {
                return res
            })
    },
    getWpCode: function( params) {
        return request(`api/publisher/wp-code`, params || {}, 'post')
            .then((res)=> {
                return res
            })
    },
    addPayoutRequest: function ( params ) {
        return request(`api/publisher/add-payout-request`, params || {}, 'get')
            .then((res)=> {
                return res
            })
    },
    createNewSite: function ( params ) {
        return request(`api/publisher/addsite`, params || {}, 'post')
            .then((res)=> {
                return res
            })
    }
};

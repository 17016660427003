<template>
  <div class="base-modal flex">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'BaseModal',
    beforeDestroy() {
      this.toggleNoScroll(false)
    },
    created() {
      this.toggleNoScroll(true)
    }
  };
</script>

<style scoped lang="scss">
  .base-modal {
    padding: 0;
    justify-content: center;
  }
  .close-btn {
    align-self: flex-end;
    cursor: pointer;
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    color: rgba(255,255,255,.3);

    &:hover{
      color:rgba(255,255,255,.9);
    }
  }

</style>

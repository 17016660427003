import Vue from 'vue';
import initialState from './initialState';

export default {
    'SET_AUTH_INFO' (state, data) {
        // console.log('SET_AUTH_INFO', data);
        Vue.$storage.set('auth_info', data);
    },
    'UPDATE_USER_INFO' (state, data) {
        state.user = data;
    },
    'RESET_STATE'(state){
        let initState = initialState(); //Начальное сотояние динамических данных.
        Object.assign(state, initState);
        this.cache.clear()
    },
    'SET_VIDEO_HINTS_TO_STATISTICS_FILTER' (state, data) {
        state.statistics.videoHintList = data.filter((video)=>{
            return !state.statistics.videoFiltersList.find((item)=>{
                return video.id === item.id
            });
        }) || []
    },
    'SET_STATISTIC_VIDEO_FILTERS_LIST' (state, data) {
        state.statistics.videoFiltersList = data;
    },
    'SET_STATISTIC_FILTERS' (state, data) {
        Object.assign(state.statistics.filters, data)
    },
    // 'SET_VIDEO_HINTS_TO_VIDEOS_FILTER' (state, data) {
    //     state.videos.videoHintList = data.filter((video)=>{
    //         return !state.videos.videoFiltersList.find((item)=>{
    //             return video.id === item.id
    //         });
    //     }) || []
    // },
    'SET_STATISTIC' (state, data) {
        state.statistics.statisticData = data.data;
    },
    'SET_VIDEOS_LIST' (state, data) {
        state.videos.list = data.data;
    },
    'APPEND_STATISTIC' (state, data) {
        state.statistics.statisticData.push(...data.data);
    },
    'SET_LANGUAGES_LIST' (state, data) {
        state.videos.languages = data;
    },
    'SET_CATEGORIES_LIST_TO_NEW_VIDEO' (state, data) {
        state.videos.categories = data;
    },
    'SET_UPLOAD_PROGRESS_PERCENT' (state, data) {
        state.uploadProgressPercent = data;
    },
    'SET_WEBSITES_LIST' (state, data) {
      state.sitesList = data;
    },
    'SET_SELECTED_SITE_ID' (state, data) {
      state.currentWebsiteId = data;
    },
    'SET_TOTAL_STATISTIC' (state, data) {
      state.statistics.totalStatisticData = data.total;
    },
    'SET_ARTICLE_HINTS_TO_STATISTICS_FILTER' (state, {data}) {
      state.statistics.articleHintList = data.filter((article)=>{
        return !state.statistics.articlesFiltersList.find((item)=>{
          return article.id === item.id
        });
      }) || [];
    },
    'SET_STATISTIC_ARTICLES_FILTERS_LIST' (state, data) {
      state.statistics.articlesFiltersList = data;
    },
    'SET_VIDEOS_CATEGORIES_LIST' (state, data) {
      state.videos.categories = data;
    },
    'SET_VIDEOS_AUTHORS_LIST' (state, data) {
      state.videos.authors = data;
    },
    'SET_ARTICLES_FILTERS' (state, data) {
      Object.assign(state.articles.filters, data)
    },
    'SET_ARTICLES_LIST' (state, data) {
      state.articles.articlesData = data.data;
    },
    'APPEND_ARTICLES_LIST' (state, data) {
      state.articles.articlesData.push(...data.data);
    },
}

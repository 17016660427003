import Vue from 'vue';
import Vuex from 'vuex'; // https://vuex.vuejs.org/ru/guide/
import createCache from 'vuex-cache'; // Библиотека для кеширвоания запросов к нашему апи. https://www.npmjs.com/package/vuex-cache

import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import initialState from './initialState'; // Динамические данные. Можно обнулить мутацией  RESET_STATE. Например после логина или разлогина, чтобы компоненты адекватно подтянули изменения

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createCache({ timeout: 1000 * 60 * 60 })], // По умолчанию запросы через $store.cache хранятся час
  state: Object.assign({
    user: null,
    googleAuth: null,
    authorization_info: null,
    static: {
      staticDomain: process.env.VUE_APP_STATIC_HOST ? process.env.VUE_APP_STATIC_HOST : '',
      mail_to_contact_form: 'your-email@test.gg',
      contact_email: 'support@distribune.com',
      wp_plugin_link: process.env.VUE_APP_AFTER_REGISTRATION_REDIRECT_LINK,
      wp_plugin_name: 'Distribune wordpress plugin',
      statisticFiltersGroupsAliases: {
        video: 'video',
        date: 'date',
      },
      statisticSortingAliases: {
        video: 'video',
        date: 'date',
        views: 'views',
        complete: 'complete',
        clicks: 'clicks',
      },
      routePaths: {
        webmasters: '/webmasters',
        contentOwners: '/content-owners',
        bloggers  : '/bloggers',
        aboutUs: '/about-us',
        registrationOld: '/registration',
        registrationActivate: '/signup/activate/:hash',
        passRestore: '/signin/change',
        dashboard: '/dashboard',
        dashboardp: '/dashboardp',
        statistics: 'statistics',
        channels: 'channels',
        articles: 'articles',
        videos: 'videos',
        addVideo: '/add-video',
        documents: '/documents',
        legalDocuments: '/documents/legal-documents',
        privacyPolicy: '/documents/privacy-policy',
        agreement: '/documents/agreement',
        // Distribune app routes
        login: '/app/signin',
        registration: '/app/signup',
        webmasterRegistration: '/app/signup/2',
        bloggerRegistration: '/app/signup/1',
      }
    },
  },
    initialState()
  ),
  mutations,
  actions,
  getters
});

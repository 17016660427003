import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

export default (()=>{
  const requireComponent = require.context(
    // Относительный путь до каталога компонентов
    '../components/base-components',
    // Обрабатывать или нет подкаталоги
    false,
    // Регулярное выражение для определения файлов базовых компонентов
    /Base[A-Z]\w+\.(vue|js)$/
  );

  requireComponent.keys().forEach((fileName) => {
    // Получение конфигурации компонента
    const componentConfig = requireComponent(fileName);

    // Получение имени компонента в PascalCase
    const componentName = upperFirst(
      camelCase(
        // Получаем имя файла независимо от глубины вложенности
        fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, ''),
      ),
    );

    // Глобальная регистрация компонента

    Vue.component(
      componentName,
      // Поиск опций компонента в `.default`, который будет существовать,
      // если компонент экспортирован с помощью `export default`,
      // иначе будет использован корневой уровень модуля.
      componentConfig.default || componentConfig,
    );
  });
})()

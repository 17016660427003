<template>
  <img class="image"
       alt="picture"
       v-if="!error"
       :src="src"
       @error="error = true"
       @load="onload = true"
       :class="{loaded: onload}"
  >
<!--  <img class="base-image" v-else :src="baseImg" alt="picture">-->
</template>

<script>
  export default {
    name: 'BaseImage',
    data(){
      return {
        error: false,
        onload: false
      }
    },
    props: {
      src: null,
      // baseImg: {
      //   default(){
      //     return require('../../assets/img/image.png') // Картинка по умолчанию
      //   }
      // }
    },
    watch:{
      src(){
        this.error = false;
        this.onload = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .image {
      opacity: 0;

      &.loaded {
        opacity: 1;
      }
      transition: opacity 0.4s ease-in;
    }
  .base-image {
    object-fit: contain!important;
    object-position: center center!important;
  }
</style>

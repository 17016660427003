import request from './request';

export default {
    getVideoByTitle: function( params) {
        const userType = params.userType === 1 ? 'blogger' : 'publisher';
        return request(`api/${userType}/videos?search=${params.query}`, params || {}, 'get')
            .then((res)=> {
                return res.data
            })
    },
    getStatistic: function( params) {
        const userType = params.userType === 1 ? 'blogger' : 'publisher';
        return request(`api/${userType}/statistic${params.query}`, params || {}, 'get')
            .then((res)=> {
                return res
            })
    },

};

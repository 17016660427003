import request from './request';

export default {
    getVideoByTitle: function( params) {
        return request(`api/blogger/video?title=${params.query}`, params || {}, 'get')
            .then((res)=> {
                return res
            })
    },
    getVideoStatus: function(params) {
        return request(`api/video-status?id=${params.id}&c=${params.c}`,  {}, 'get')
            .then((res)=> {
                return res
            })
    },
    getVideosList: function(params) {
        return request(`api/${params.userType}/videos${params.query}`,  {}, 'get')
            .then((res)=> {
                return res
            })
    },
    validateVideoForm: function( params) {
        return request(`api/blogger/video-validate`, params || {}, 'post')
            .then((res)=> {
                return res
            })
    },
    createNewVideo: function( params) {
        return request(`api/blogger/videos`, params || {}, 'post', )
            .then((res)=> {
                return res
            })
    },
    getLanguages: function( params) {
        return request(`api/languages`, params || {}, 'get', )
            .then((res)=> {
                return res
            })
    },
    getCategories: function( params) {
        return request(`api/video-categories`, params || {}, 'get', )
            .then((res)=> {
                return res
            })
    },
    getAuthorsList: function( params) {
      return request(`api/publisher/video-authors`, params || {}, 'get', )
        .then((res)=> {
          return res
        })
    },
};

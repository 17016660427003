// import Vue from 'vue';
import api from '../api/api';

export default {
    'GET_USER_INFO': async ({commit}) => {
        commit('UPDATE_USER_INFO', await api.authApi.authorisation())
    },
    'GET_USER_PUBLISHER_INFO': async ({commit}) => {
        commit('UPDATE_USER_INFO', await api.authApi.authorisationPublisher())
    },
    'REFRESH_ACCESS_TOKEN': async ({commit}) => {
        commit('SET_AUTH_INFO', await api.authApi.refresh());
    },
    'DO_LOGIN': async ({commit}, params) => {
        commit('SET_AUTH_INFO', await api.authApi.login(params))
    },
    'LOGOUT': async ({commit}, params) => {
        commit('UPDATE_USER_INFO', null);
        commit('SET_AUTH_INFO', await api.authApi.logout(params))
    },
    'DO_GOOGLE_LOGIN': async ({commit}, params) => {
        commit('SET_AUTH_INFO', await api.authApi.googleAuth(params))
    },
    'DO_REGISTRATION': async (state, params) => {
        await api.authApi.signup(params)
    },
    'ACTIVATE_LOGIN': async ({commit}, params) => {
        let response = await api.authApi.activateLogin(params);
        commit('SET_AUTH_INFO', response);
        return response;
    },
    'SEND_REQUEST_FOR_RESTORE': async (state, params) => {
        await api.authApi.requestToPassRestore(params)
    },
    'CHECK_PASWORD_RESTORE_TOKEN': async (state, payload) => {
        await api.authApi.checkPasswordRestoreToken(payload.params, payload.token)
    },
    'SET_NEW_PASSWORD': async ({commit}, params) => {
        let response = await api.authApi.setNewPassword(params);
        commit('SET_AUTH_INFO', response);
        return response
    },
    'SEARCH_VIDEO_TO_STATISTIC_FILTER': async ({commit}, params) => {
        commit('SET_VIDEO_HINTS_TO_STATISTICS_FILTER', await api.statisticsApi.getVideoByTitle(params));
    },
    'GET_STATISTIC': async (state, params) => {
        return await api.statisticsApi.getStatistic(params)
    },
    'GET_NEXT_STATISTIC_PAGE': async (state, params) => {
        return await api.statisticsApi.getStatistic(params)
    },
    'GET_VIDEOS_LIST': async (state, params) => {
        return await api.videosApi.getVideosList(params);
    },
    'GET_VIDEO_STATUS': async (state, params) => {
        return await api.videosApi.getVideoStatus(params);
    },
    'VALIDATE_VIDEO_BEFORE_SEND': async (state, params) => {
        return await api.videosApi.validateVideoForm(params)
    },
    'ADD_NEW_VIDEO': async (state, params) => {
        return await api.videosApi.createNewVideo(params)
    },
    'ADD_NEW_SITE': async (state, params) => {
        return await api.publisherApi.createNewSite(params)
    },
    'ADD_PAYOUT': async (state, params) => {
        return await api.publisherApi.addPayoutRequest(params)
    },
    'GET_LANGUAGES_FOR_NEW_VIDEO': async ({commit}, params) => {
        let response = await api.videosApi.getLanguages(params);
        commit('SET_LANGUAGES_LIST',response);
        return response;
    },
    'GET_CATEGORIES_FOR_NEW_VIDEO': async ({commit}, params) => {
        let response = await api.videosApi.getCategories(params);
        commit('SET_CATEGORIES_LIST_TO_NEW_VIDEO',response);
        return response;
    },
    'GET_WEBSITES_LIST': async ({commit}, params) => {
      commit('SET_WEBSITES_LIST', await api.publisherApi.getWebsitesList(params))
    },
    'GET_ARTICLES': async (state, params) => {
      return await api.articleModel.getArticlesList(params);
    },
    'GET_VIDEOS_CATEGORIES': async ({commit}, params) => {
      let response = await api.videosApi.getCategories(params);
      commit('SET_VIDEOS_CATEGORIES_LIST',response);
      return response;
    },
    'GET_VIDEOS_AUTHORS_LIST': async ({commit}, params) => {
      let response = await api.videosApi.getAuthorsList(params);
      commit('SET_VIDEOS_AUTHORS_LIST',response);
      return response;
    },
    'CREATE_NEW_ARTICLE': async (state, params) => {
      return await api.articleModel.createNewArticle(params);
    },
    'GET_VAST_DATA': async (state, params) => {
      return await api.publisherApi.getVASTData(params);
    },
    'GET_CODE': async (state, params) => {
        return await api.publisherApi.getCode(params);
    },
    'GET_WP_CODE': async (state, params) => {
        return await api.publisherApi.getWpCode(params);
    },
    'SEARCH_ARTICLE_TO_STATISTIC_FILTER': async ({commit}, params) => {
      commit('SET_ARTICLE_HINTS_TO_STATISTICS_FILTER', await api.articleModel.getArticlesList(params));
    },
}

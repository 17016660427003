const en = {

    menu: {
        home: 'Home',
        publishers: 'Publishers',
        contentOwners: 'Content Owners',
        blog: 'Blog',
        signUp: 'Sign Up',
        login: 'Login',
        documents: 'Documents',
    },

    buttons: {
        signUp: 'Sign Up',
        download: 'Download',
        submit: 'Submit',
    },

    footer: {
        pcp: 'Privacy and Cookie Policy',
        pa: 'Partnership Agreement',
        ars: 'All rights reserved',
    },

    homeScreen: {
        intro: {
            title: '<span>An additional <i>instrument of audience monetization</i> with video content for <i>publishers</i>.</span><span>The solution to <i>increase audience</i> reach for <i>video content owners</i>.</span>',
        },
    },

    documentsScreen: {
        intro: {
            title: 'Documents',
        },
        legalDocuments: {
            title: 'Legal documents',
            item1: {
                title: 'User relationship with Distribune',
                text: 'Selection of MGID services. It is the responsibility of the user to select the appropriate MGID service that adequately meets the user\'s needs, subject to the applicable MGID fees and charges.',
            },
            item2: {
                title: 'text 2',
                text: 'Selection of MGID services. It is the responsibility of the user to select the appropriate MGID service that adequately meets the user\'s needs, subject to the applicable MGID fees and charges.',
            },
            item3: {
                title: 'text 3',
                text: 'Selection of MGID services. It is the responsibility of the user to select the appropriate MGID service that adequately meets the user\'s needs, subject to the applicable MGID fees and charges.',
            },
        },
        privacyPolicy: {
            title: 'Privacy policy',
            item1: {
                title: 'title 1',
                text: 'Selection of MGID services. It is the responsibility of the user to select the appropriate MGID service that adequately meets the user\'s needs, subject to the applicable MGID fees and charges.',
            },
            item2: {
                title: 'title 2',
                text: 'Selection of MGID services. It is the responsibility of the user to select the appropriate MGID service that adequately meets the user\'s needs, subject to the applicable MGID fees and charges.',
            },
            item3: {
                title: 'title 3',
                text: 'Selection of MGID services. It is the responsibility of the user to select the appropriate MGID service that adequately meets the user\'s needs, subject to the applicable MGID fees and charges.',
            },
        },
        agreement: {
            title: 'Agreement',
            item1: {
                title: 'title 1',
                text: 'Selection of MGID services. It is the responsibility of the user to select the appropriate MGID service that adequately meets the user\'s needs, subject to the applicable MGID fees and charges.',
            },
            item2: {
                title: 'title 2',
                text: 'Selection of MGID services. It is the responsibility of the user to select the appropriate MGID service that adequately meets the user\'s needs, subject to the applicable MGID fees and charges.',
            },
            item3: {
                title: 'title 3',
                text: 'Selection of MGID services. It is the responsibility of the user to select the appropriate MGID service that adequately meets the user\'s needs, subject to the applicable MGID fees and charges.',
            },
        },
    },

    webmastersScreen: {
        intro: {
            title: '<span>The unique platform serving the publisher as an <i>additional website monetization</i> source with the help of video content</span>'
        },
    },

    registrationScreen: {
        title: 'Sign up',
    },

    contentOwnersScreen: {
        title: '<span>The unique platform serving <strong>to popularize your video content</strong> and engage new audiences</span>',
    },

    about: {
        title: 'About',
        text: '<p><strong>Distribune is a complex solution for video content distribution</strong>, <strong>providing publishers with a ready-to-use video player block</strong>, licensed content from its owners and <strong>audience monetization capabilities</strong>.</p><p>In addition, content owners <strong>can reach new audiences</strong> by placing and playing their videos on external resources.</p>',
    },

    whyVideo: {
        title: 'What makes video content special?',
        slide1: {
            text1: 'A user spends on average <b>88% more time</b> on websites containing video content.',
            text2: '<b>Distribune</b> provides a publisher with a ready-to-use video player and <b>licensed content</b>.',
        },
        slide2: {
            text1: '<b>More than 85%</b> of internet users watch online video content every month.',
            text2: '<b>Distribune</b> offers a wide range of video categories to <b>satisfy publishers needs</b>.',
        },
        slide3: {
            text1: '<b>СPM</b> of video ads starts at $1.50.',
            text2: '<b>Distribune</b> is an instrument of revenue generation for publishers with the help of high rate ads.',
        },
    },

    weOffer: {
        title: 'What we offer',
        publisher: {
            title: 'Publishers:',
            text1: 'Receive full access to a variety of videos.',
            text2: 'Use our proprietary video player, which can be easily integrated with the help of <b>WordPress plugin</b> or <b>generated video code</b>.',
            text3: '<b>Increase time</b> users spend on site.',
            text4: '<b>Profit</b> by displaying our advertisers’ video ads.',
        },
        contentOwner: {
            title: 'Content Owners:',
            text1: '<b>Expand audience</b> reach by entering other platforms.',
            text2: 'Receive additional Distribune stats and analytics regarding audience reach.',
        },
        signUp: 'SIGN UP',
    },

    keyFacts: {
        title: 'Key facts',
        item1: '<span>300+</span> partnering websites',
        item2: '<span>4000+</span> video units',
        item3: '<span>100+</span> content owners',
        item4: '<span>1+ billion</span> hours of video views monthly',
    },

    howWorks: {
        title: 'How it works',
        item1: 'Content owners sign up and provide their video content.',
        item2: 'Content is approved on our platform, assigned a category and becomes available to be placed on a website.',
        item3: 'Publishers are provided with video player code along with selected content.',
        item4: 'Video is published on a website beginning the monetization process.',
        item5: 'Publishers acquire revenue from pre-roll video ads; content owners increase audience reach and views.',
        pic: '/img/how-works__pic-2_en.png',
    },

    offersPub: {
        title: 'How we work with publishers',
        subTitle: 'Distribune offers publishers two options of video player integration.',
        wp: {
            title: '<span>WordPress plugin</span>',
            item1: 'Publishers sign up on <strong>distribune.com</strong>',
            item2: 'Receive an email with a WordPress plugin <strong>installation code</strong>',
            item3: '<strong>Install the plugin</strong> on their website',
            item4: 'Open the plugin and <strong>select the video content</strong> that best matches their website',
            item5: '<strong>Integrate the video player</strong> into a website page in just one click',
            item6: 'The <strong>monetization</strong> process begins',
        },
        ui: {
            title: '<span>Web-interface</span>',
            item1: 'Publishers sign up on <strong>distribune.com</strong>',
            item2: 'Receive an email with client dashboard <strong>login code</strong>',
            item3: 'Choose <strong>video content</strong> on the client dashboard',
            item4: 'Receive a <strong>generated video code</strong> from the system',
            item5: '<strong>Integrate the video player</strong> into a website page',
            item6: 'The <strong>monetization</strong> process begins',
        },
    },

    loadPlugin: {
        title: 'Download <span>WordPress plugin</span> and start monetizing your audience right away',
        text: '<strong>Distribune</strong> boosts publishers’ revenue growth by placing video ads from brands in our video player.<br>With Distribune, we aren’t competing with other ad placements on a website, <strong>guaranteeing additional income</strong>.',
    },

    benefitsPub: {
        title: 'Advantages for publishers',
        text1: 'We provide publishers with a <strong>free video player</strong>, which is easily integrated for any website’s interface. Moreover, for websites, created on the WordPress platform, <strong>we developed a special plugin</strong> for simple installation of a video player.',
        text2: 'Our <strong>free video content base</strong> consists of more than 4000 videos of varying topics.',
        text3: '<strong>We work only with licensed video content</strong>, and every video is examined to check its legality. In addition, every content owner signs an agreement with Distribune allowing us to use their videos on our platform and partnering websites.',
        text4: 'Our video content base makes it possible to create <strong>whole video sections on a website</strong> increasing user experience and popularity of your resource.',
    },

    formRegistration: {
        cooperation: {
            label: 'Cooperation type',
            placeholder: 'Choose a type',
        },
        name: {
            label: 'Your name',
            placeholder: 'Name',
        },
        email: {
            label: 'Your email',
            placeholder: 'Email',
        },
        messenger: {
            label: 'Messenger',
            placeholder: 'Choose a messenger',
        },
        site: {
            label: 'Your website / Channel url',
            placeholder: 'https:',
        },
        user: {
            label: 'Username',
            placeholder: 'Name',
        },
        submit: 'Sign up',
    },

    contactUs: {
        title: 'Do you still have questions?',
        text: 'We’d love to hear them. Feel free to submit your question by filling out the form below',
    },

    formContactUs: {
        cooperation: {
            label: 'Cooperation type',
            placeholder: 'Select type',
        },
        name: {
            label: 'Your name',
            placeholder: 'Name',
        },
        email: {
            label: 'Your email',
            placeholder: 'Email',
        },
        message: {
            label: 'Your question',
            placeholder: 'Question',
        },
        submit: 'Submit',
    },

    formMessages: {
        emptyName: 'Name is required',
        incorrectName: 'Name must contain only letters',
        emptyEmail: 'Email is required',
        incorrectEmail: 'Incorrect email',
        emptyType: 'Type of cooperation is not selected',
        emptyQuestion: 'Question is required',
        emptyMessenger: 'Messenger is not selected',
        incorrectWebsite: 'Website is incorrect',
        emptyWebsite: 'Website is required',
        errors: 'Please correct the following error(s):',
        success: 'Thank you. Your info has been sent to our specialists.',
    },

    aboutOwner: {
        title: 'How we work with content owners',
        item1: 'Content owners sign up on <strong>distribune.com</strong>.',
        item2: 'Content owners <strong>sign an agreement</strong> that allows us to use their videos on our platform and partnering websites.',
        item3: 'Content owners <strong>provide links to their video content</strong> or video file.',
        item4: 'Our managers <strong>select a suitable video category</strong> and launch the video on partnering websites.',
        item5: 'Content owners receive <strong>access to video views</strong> and click statistics on their client dashboard.',
        item6: 'They can use these additional statistics for <strong>more advantages promo integrations</strong>.',
    },

    ownersBenefits: {
        title: 'Advantages for content owners',
        item1: 'Your video is seen not only by your followers but also by audiences of other resources.',
        item2: 'Your video can be published on more than <strong>300 Distribune partnering websites</strong>, and we are working continuously to expand our client base.',
        item3: 'We provide you with additional <strong>analytics for your video views</strong>. These additional views will help you to level up your promotional integrations.',
        item4: 'The integral part of <strong>Distribune</strong> video players is a special button redirecting a user to your resource. This feature will allow for you to engage with new audiences free of charge.',
    },

};

export default en;

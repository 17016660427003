import request from './request';
import config from "../../config";

export default {
    signup: function( params) {
        return request('api/signup', params || {}, 'post', true)
            .then((res)=> {
                return res
            })
    },
    activateLogin: function( params) {
        return request('api/signup/activate/' + params.hash, params || {}, 'get', true)
            .then((res)=> {
                return res
            })
    },
    login: function( params) {
        Object.assign(params);

        return request('api/signin', params || {}, 'post', true)
            .then((res)=> {
                return res
            })
    },
    refresh: function () {
        return request('api/refresh', {}, 'post')
            .then((res)=> {
                return res
            })
    },
    googleAuth: function( params) {
        Object.assign(params, {user_type: config.API.USER_TYPE});

        return request('api/social-auth/google', params || {}, 'post', true)
            .then((res)=> {
                return res
            })
    },
    logout: function( params) {
        return request('api/logout', params || {}, 'get')
            .then(()=> {
                return null
            })
    },
    authorisation: function () {
        return request('api/blogger/user', {}, 'get')
            .then((res)=> {
                return res
            })
    },
    authorisationPublisher: function () {
      return request('api/publisher/user', {}, 'get')
        .then((res)=> {
          return res
        })
    },
    requestToPassRestore: function (params) {
        return request('api/password/create', params || {})
            .then((res)=> {
                return res
            })
    },
    checkPasswordRestoreToken: function (params, token) {
        return request('api/password/check-token/' + token, params || {}, 'get')
            .then((res)=> {
                return res
            })
    },
    setNewPassword: function (params) {
        return request('api/password/change', params || {})
            .then((res)=> {
                return res
            })
    },
};

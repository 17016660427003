import Vue from 'vue';
import axios from 'axios';
import config from '../../config';
import _ from 'lodash';
import store from "../../store/store";

let baseUrl = `${config.API.API_HOST}/`;

let getHeaders = (isPublicRequest) => {
  if (isPublicRequest) return {};

  if (Vue.$storage.get('auth_info')) {
    return {
      'Authorization': Vue.$storage.get('auth_info').token_type + ' ' + Vue.$storage.get('auth_info').access_token
    };
  }
  return {};

};
export default function (url, params = {}, method, isPublicRequest) {
  return axios({
    url: url,
    method: method || 'post',
    baseURL: baseUrl,
    // timeout: 30000, //без таймаута для загрузки видео
    headers: getHeaders(isPublicRequest),
    onUploadProgress: function (progressEvent) {
      store.commit('SET_UPLOAD_PROGRESS_PERCENT', Math.round((progressEvent.loaded * 100) / progressEvent.total));
    },
    cancelToken: new axios.CancelToken(function (cancel) {
      store.state.axiosCancel = cancel; // Дикий костыль, промашка в начальной архитектуре. Поскольку axios не дает прямого метода отмены запроса, пришлось выносить это в глобальную переменную. И в ней находится лишь самый последний запрос. По идеи надо передавать этот обработчик внутрь actions vuex(a) и там это все привязывать к конкретному запросу. На момент написания этого кода это уже было невозможно
    }),
    transformRequest: [function (data) {
      if (data instanceof FormData) return data;
      let fd = new FormData();

      _.forIn(data, (value, key) => {
        fd.append(key, value)
      });
      return fd;
    }],
    data: params,
  })
    .then((res)=>{
      return res.data;
    })

    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log('error.response.data',error.response.data);
        // console.log('error.response.status',error.response.status);
        // console.log('error.response.headers',error.response.headers);
        return Promise.reject(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log('error.request',error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
      }

      return Promise.reject(error);
    })
}

const ru = {

    menu: {
        home: 'Главная',
        publishers: 'Вебмастерам',
        contentOwners: 'Владельцам контента',
        blog: 'Блог',
        signUp: 'Регистрация',
        login: 'Вход',
        documents: 'Документы',
    },

    buttons: {
        signUp: 'Регистрация',
        download: 'Скачать',
        submit: 'Отправить',
    },

    footer: {
        pcp: 'Политика Конфиденциальности',
        pa: 'Партнерское соглашение',
        ars: 'Все права защищены',
    },

    homeScreen: {
        intro: {
            title: '<span>Дополнительный <i>инструмент монетизации</i> аудитории сайта с помощью видео для <i>вебмастеров</i>.</span><span>Эффективный способ <i>увеличить охват</i> аудитории для <i>владельцев видеоконтента</i>.</span>',
        },
    },

    documentsScreen: {
        intro: {
            title: 'Документы',
        },
        legalDocuments: {
            title: 'Юридические документы',
            item1: {
                title: 'Отношения пользователя с Distribune',
                text: 'Выбор услуг MGID. Пользователь несет ответственность за выбор соответствующей услуги MGID, которая должным образом отвечает потребностям пользователя, с учетом соответствующих сборов и сборов MGID.',
            },
            item2: {
                title: 'Принятие условий',
                text: 'Выбор услуг MGID. Пользователь несет ответственность за выбор соответствующей услуги MGID, которая должным образом отвечает потребностям пользователя, с учетом соответствующих сборов и сборов MGID.',
            },
            item3: {
                title: 'Оплата комиссий',
                text: 'Выбор услуг MGID. Пользователь несет ответственность за выбор соответствующей услуги MGID, которая должным образом отвечает потребностям пользователя, с учетом соответствующих сборов и сборов MGID.',
            },
        },
        privacyPolicy: {
            title: 'Политика конфиденциальности',
            item1: {
                title: 'Использование Сервисов пользователем',
                text: 'Выбор услуг MGID. Пользователь несет ответственность за выбор соответствующей услуги MGID, которая должным образом отвечает потребностям пользователя, с учетом соответствующих сборов и сборов MGID.',
            },
            item2: {
                title: 'Некоммерческое использование',
                text: 'Выбор услуг MGID. Пользователь несет ответственность за выбор соответствующей услуги MGID, которая должным образом отвечает потребностям пользователя, с учетом соответствующих сборов и сборов MGID.',
            },
            item3: {
                title: 'Общие условия использования и хранения',
                text: 'Выбор услуг MGID. Пользователь несет ответственность за выбор соответствующей услуги MGID, которая должным образом отвечает потребностям пользователя, с учетом соответствующих сборов и сборов MGID.',
            },
        },
        agreement: {
            title: 'Партнерское соглашение',
            item1: {
                title: 'Сбор информации на сайте',
                text: 'Выбор услуг MGID. Пользователь несет ответственность за выбор соответствующей услуги MGID, которая должным образом отвечает потребностям пользователя, с учетом соответствующих сборов и сборов MGID.',
            },
            item2: {
                title: 'Информация, позволяющая установить личность',
                text: 'Выбор услуг MGID. Пользователь несет ответственность за выбор соответствующей услуги MGID, которая должным образом отвечает потребностям пользователя, с учетом соответствующих сборов и сборов MGID.',
            },
            item3: {
                title: 'Текст 3',
                text: 'Выбор услуг MGID. Пользователь несет ответственность за выбор соответствующей услуги MGID, которая должным образом отвечает потребностям пользователя, с учетом соответствующих сборов и сборов MGID.',
            },
        },
    },

    webmastersScreen: {
        intro: {
            title: '<span>Платформа, которая позволяет <i>дополнительно монетизировать</i> сайт за счёт размещения на нем релевантного видеоконтента</span>'
        },
    },

    registrationScreen: {
        title: 'Регистрация',
    },

    contentOwnersScreen: {
        title: '<span>Уникальная платформа для <strong>популяризации вашего видеоконтента</strong> и привлечения новой аудитории</span>',
    },

    about: {
        title: 'О компании',
        text: '<p><strong>Комплексное решение распространения видеоконтента</strong>, которое <strong>предлагает вебмастерам готовый к использованию видеоплеер</strong>, лицензионный контент от его владельцев и <strong>возможность монетизации аудитории</strong>.</p><p>Владельцы контента, в свою очередь, <strong>получают дополнительный охват</strong> пользователей благодаря просмотру их видеоконтента на других ресурсах.</p>',
    },

    whyVideo: {
        title: 'Почему видеоконтент?',
        slide1: {
            text1: 'Пользователь проводит на <b>88% больше времени</b> на сайте с видеоконтентом.',
            text2: 'С <b>Distribune</b> вебмастер использует уже разработанный нами видеоплеер с <b>лицензионным контентом</b>.',
        },
        slide2: {
            text1: '<b>Более 85%</b> интернет пользователей просматривают видеоконтент онлайн ежемесячно.',
            text2: 'С <b>Distribune</b> вебмастер выбирает категорию видеоконтента, которая <b>соответствует тематике сайта</b>.',
        },
        slide3: {
            text1: 'Стоимость видео-формата в интернете одна из <b>самых высоких</b> для рекламодателя.',
            text2: 'С <b>Distribune</b> вебмастер зарабатывает благодаря <b>высокоприбыльной рекламе</b>.',
        },
    },

    weOffer: {
        title: 'Что мы предлагаем',
        publisher: {
            title: 'Вебмастер:',
            text1: 'Использует разработанный нами видеоплеер, который легко интегрируется с помощью <b>WordPress плагина</b> или путем <b>простой интеграции кода видео на странице</b>.',
            text2: 'Получает доступ к базе лицензионного видеоконтента в более чем <b>20 тематиках</b>.',
            text3: '<b>Увеличивает время</b>, которое пользователь проводит на сайте.',
            text4: '<b>Зарабатывает</b> на показе видеороликов наших рекламодателей.',
        },
        contentOwner: {
            title: 'Владелец контента:',
            text1: '<b>Увеличивает узнаваемость</b> своего <b>контента</b> с возможностью отслеживания статистики.',
            text2: 'Получает <b>выход на другие площадки</b> для увеличения охвата аудитории.',
        },
        signUp: 'РЕГИСТРАЦИЯ',
    },

    keyFacts: {
        title: 'Ключевые факты',
        item1: 'Работаем<br>с более, чем<span>300+</span>сайтами-партнерами',
        item2: 'Предоставляем<span>4000+</span>единиц видеоконтента',
        item3: 'Сотрудничаем<br>с более, чем<span>100+</span>правообладателями',
        item4: 'Обеспечиваем<br>более<span>1 млрд+</span>часов просмотров<br>видео в месяц',
    },

    howWorks: {
        title: 'Как это работает',
        item1: 'Владелец видеоконтента <strong>регистрируется в системе</strong> и предоставляет свой контент.',
        item2: 'Видеоконтент утверждается на платформе; ему <strong>присваивается категория</strong>, видео становится доступным для публикации на сайте.',
        item3: 'Вебмастер видит <strong>новые видео</strong> интересующей его тематики.',
        item4: 'Релевантный контент интегрируется на сайт и запускается процесс <strong>автоматической монетизации</strong>.',
        item5: 'Вебмастер получает <strong>дополнительную монетизацию</strong> от новых форматов, владелец контента – <strong>дополнительный охват, просмотры и узнаваемость</strong>.',
        pic: '/img/how-works__pic-2.png',
    },

    offersPub: {
        title: 'Как мы работаем с вебмастерами',
        subTitle: 'Distribune предлагает вебмастерам две возможности установки видеоплеера на сайте',
        wp: {
            title: 'С помощью<span>WordPress <br>плагина</span>',
            item1: 'Вебмастер регистрируется на сайте <strong>distribune.com</strong>.',
            item2: 'Получает на email <strong>код для установки</strong> WordPress плагина.',
            item3: '<strong>Устанавливает плагин</strong> на сайте.',
            item4: 'Входит в плагин и <strong>выбирает видеоконтент</strong>, который соответствует тематике сайта.',
            item5: '<strong>Интегрирует плеер</strong> на страницу сайта в один клик.',
            item6: 'С этого момента начинается процесс <strong>монетизации аудитории</strong> за счет показа рекламы в плеере.',
        },
        ui: {
            title: 'С помощью<span>web-интерфейса</span>',
            item1: 'Вебмастер регистрируется на сайте <strong>distribune.com</strong>.',
            item2: 'Получает на email <strong>код для входа</strong> в личный кабинет.',
            item3: 'В личном кабинете <strong>выбирает видеоконтент</strong>, который соответствует тематике сайта.',
            item4: 'Система <strong>генерирует код</strong> видео.',
            item5: 'Вебмастер <strong>интегрирует код видео</strong> на странице со статьей и интегрирует ads.txt для максимальной монетизации.',
            item6: 'С этого момента <strong>начинается процесс монетизация</strong> аудитории за счёт показа рекламы в плеере.',
        },
    },

    loadPlugin: {
        title: 'Скачайте<span>WordPress плагин</span>и начинайте зарабатывать',
        text: '<strong>Distribune</strong> расширяет возможности заработка для вебмастеров за счет показа высокоприбыльной видеорекламы от мировых брендов.<br>При этом, мы не конкурируем с другими рекламными позициями на сайте, то есть <strong>предлагаем вебмастеру гарантированный дополнительный заработок</strong>.',
    },

    benefitsPub: {
        title: 'Преимущества для вебмастеров',
        text1: 'Мы предоставляем <strong>бесплатный видеоплеер</strong>, который легко интегрируется в интерфейс любого сайта. Более того, для сайтов, созданных на платформе WordPress, мы <strong>разработали специальный плагин</strong> для простой установки плеера на страницах сайта.',
        text2: '<strong>База бесплатного видеоконтента</strong> насчитывает более 4000 роликов на любую тематику непосредственно от владельцев контента.',
        text3: '<strong>Мы работаем только с лицензионным видеоконтентом</strong>. Каждый ролик проверяется на предмет легальности, а владелец контента, который его предоставил, подписывает с Distribune соглашение на его использование.',
        text4: 'На базе нашего контента можно строить <strong>полноценные видеоразделы на сайте</strong>, тем самым повышая пользовательский опыт и популярность вашего ресурса.',
    },

    formRegistration: {
        cooperation: {
            label: 'Тип сотрудничества',
            placeholder: 'Выберите тип',
        },
        name: {
            label: 'Ваше имя',
            placeholder: 'Имя',
        },
        email: {
            label: 'Ваш email',
            placeholder: 'Email',
        },
        messenger: {
            label: 'Мессенджер',
            placeholder: 'Выберите мессенджер',
        },
        site: {
            label: 'Ваш сайт / ссылка на канал',
            placeholder: 'https:',
        },
        user: {
            label: 'Имя пользователя',
            placeholder: 'Имя',
        },
        submit: 'Регистрация',
    },

    contactUs: {
        title: 'Остались вопросы?',
        text: 'Задайте их нам, и мы ответим в ближайшее время',
    },

    formContactUs: {
        cooperation: {
            label: 'Тип сотрудничества',
            placeholder: 'Выберите тип',
        },
        name: {
            label: 'Ваше имя',
            placeholder: 'Имя',
        },
        email: {
            label: 'Ваш email',
            placeholder: 'Email',
        },
        message: {
            label: 'Ваш вопрос',
            placeholder: 'Вопрос',
        },
        submit: 'Отправить',
    },

    formMessages: {
        emptyName: 'Заполните ваше имя',
        incorrectName: 'Поле "Имя" должно содержать только буквы',
        emptyEmail: 'Укажите e-mail',
        incorrectEmail: 'Почта указана неверно',
        emptyType: 'Выберите тип сотрудничества',
        emptyQuestion: 'Опишите ваш вопрос',
        emptyMessenger: 'Выберите мессерджер для связи',
        incorrectWebsite: 'Сайт указан неверно',
        emptyWebsite: 'Укажите адрес сайта',
        errors: 'Пожалуйста, исправьте следующие ошибки',
        success: 'Спасибо. Ваше сообщение было отправлено нашим специалистам.',
    },

    aboutOwner: {
        title: 'Как мы работаем с владельцами контента',
        item1: 'Владелец контента регистрируется на сайте <strong>distribune.com</strong>.',
        item2: 'Мы <strong>подписываем договор</strong> о согласии использования его видеоконтента на нашем сервисе и сайтах-партнерах.',
        item3: 'Владелец контента <strong>предоставляет ссылки на свой видеоконтент</strong> либо файл с видеоконтентом и ссылку перехода.',
        item4: 'Наши <strong>менеджеры подбирают соответствующую категорию</strong> для видео и запускают его отображение на сайтах-партнерах.',
        item5: 'В личном кабинете владелец контента получает <strong>доступ к статистике по просмотрам</strong> его видеороликов и переходам на его ресурс.',
        item6: 'Использует эту дополнительную статистику для согласования более <strong>выгодных рекламных интеграций</strong>.',
    },

    ownersBenefits: {
        title: 'Преимущества для владельцев контента',
        item1: 'Видео просматривает не только ваша аудитория, но и аудитория других площадок. Вы получаете бесплатный дополнительный охват вашего контента.',
        item2: 'Ваше видео потенциально может отображаться на более, чем <strong>300 сайтах-партнерах Distribune</strong>, и мы все время работаем над увеличением этой базы.',
        item3: 'Мы предоставляем <strong>аналитику по показам ваших видео</strong> с помощью нашего сервиса. Эти дополнительные просмотры помогут повысить цену ваших рекламных интеграций.',
        item4: 'Неотъемлемая часть видео плеера <strong>Distribune</strong> — кнопка перехода непосредственно на ваш ресурс. Таким образом вы бесплатно увеличиваете его посещаемость.',
    },

};

export default ru;

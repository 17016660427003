<template>
  <label :class="{'error': error,'password': isPassword, 'visible-password': passwordIsVisible}" >
    <input  v-bind="$attrs"
            ref="input"
            @input="processInput"
            @keyup="onKeyUp"
    >
    <img :src="`${staticDomain}/img/show-password-icon.png`" alt=""
         class="toggle-password-visibility-icon"
         v-show="isPassword"
         @click="togglePasswordVisibility"
    >
  </label>
</template>

<script>
  export default {
    name: 'BaseInput',
    inheritAttrs: false, //Все атрибуты которые навешаны через родителя, будут не корневом елементе этого компонента, а на том где указано v-bind="$attrs"
    data() {
      return {
        inputData: '',
        passwordIsVisible: false,
        staticDomain: this.$store.state.static.staticDomain,
      }
    },
    props: {
      error: null,
      isPassword: null,
    },
    methods: {
      processInput(e){
        this.inputData = e.target.value;
        this.$emit('input', e.target.value);
      },
      onKeyUp(e){
        this.$emit('keyup', e);
      },
      togglePasswordVisibility(){
        this.$refs.input.type === 'password' ? this.$refs.input.type = 'text' : this.$refs.input.type = 'password';
        this.passwordIsVisible = !this.passwordIsVisible;
      }
    }
  };
</script>

<style lang="scss" scoped>
  label {
    position: relative;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;

    &.error {
      input {
        border: 1px solid #f05145;
      }
    }
    &.password {
      input {
        padding:  0 42px 0 22px;
      }
    }
    &.visible-password {
      .toggle-password-visibility-icon {
        opacity: 1;
      }
    }
    .toggle-password-visibility-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      opacity: 0.5;
    }

  }
  input {
    border: 1px solid #afc7da;
    box-shadow: none;
    border-radius: 2px;
    height: 52px;
    line-height: 1.428571429;
    width: 100%;
    margin: 10px 0 15px;
    padding: 0 22px;
    color: #89a7c1;
    font-weight: 500;

    &:focus {
       outline: none;
     }
    &::placeholder {
      color: #89a7c1;
      font-weight: 500;
    }
  }
</style>

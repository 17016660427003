<template>
    <div class="flex-between dropdown" @click="isOpen =!isOpen" v-click-outside="closeList">
        <span ><slot>{{currentSelector.selectorTitle}}</slot></span>
        <div class="triangle" :class="{open : isOpen}"></div>
        <div class="list"
             v-if="isOpen">
            <div class="list-item"
                 :class="{active: currentSelector === item, unactive: checkIsUnactive(item)}"
                 v-for="(item, index) in list"
                 :key="index"
                 @click.stop="selectItem(item)">
                {{item.selectorTitle}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BaseDropDownBtn",
        props: {
            list: {
                default(){
                    return []
                }
            },
            unactiveItemsList: {
                default(){
                    return []
                }
            },
            selected: null,
        },
        data(){
            return {
                isOpen: false,
                currentSelector: {}
            }
        },
        methods: {
            selectItem(item){
                if (this.checkIsUnactive(item)) return;// Если клик по неактивному пункту, то игнорируем клик

                this.isOpen = false;
                this.currentSelector = item;
                this.$emit('change', item)
            },
            checkIsUnactive(item){
                return Boolean(this.unactiveItemsList.find((val)=> {
                    return val.selectorTitle === item.selectorTitle
                }))
            },
            setActiveItem(num){
                this.currentSelector = this.list[num]
            },
            closeList(){
                this.isOpen = false;
            }
        },
        created(){
            this.currentSelector = this.defaultSelector;
            this.$parent.$on('setActiveItemOnDropdown', this.setActiveItem);
        },
        computed: {
            defaultSelector() {
                return this.selected || this.list[0]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dropdown {
        align-items: center;
        width: 100%;
        user-select: none;
        cursor: pointer;
        position: relative;
        height: 100%;
    }
    .list {
        position: absolute;
        top: 10px;
        z-index: 10;
    }
    .list-item {
        cursor: pointer;
        &.unactive {
            cursor: default;
            opacity: 0.4;
            /*pointer-events: none;*/
        }
    }
    .triangle {
        position: relative;
        &.open {
            &:after {
                bottom: -3px;
                border-top: none;
                border-bottom: 7px solid #8ba9c2;
            }
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: -12px;

            border: 7px solid transparent;
            border-top: 7px solid #8ba9c2;
        }
    }
</style>

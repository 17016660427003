<template>
    <button class="btn"
         ref='button'
         :class="{loading:processing}"
         @click="$emit('click')">
      <span class="btn-content">
        <slot></slot>
      </span>
      <BaseLoader class="processing-indicator" v-if="processing"  :color="color"></BaseLoader>
    </button>
</template>

<script>
  export default {
    name: 'BaseComponent',
    props: {
      processing: { // Для показа лоадера на кнопке
        type: Boolean,
        default: false
      },
        color: {
            default: '#fff'
        }
    },
  };
</script>

<style lang="scss" scoped>
  .btn {
    border: none;
    border-radius: 2px;
    position: relative;
    padding: 0 30px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    outline: 0;
    cursor: pointer;
    background: 0;
    height: 58px;
    white-space: nowrap;
    width: auto;
      box-shadow: none;
      text-transform: none;
      @media (max-width: 768px) {
          width: 100%;
      }
    &.loading {
      .btn-content {
        opacity: 0;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &.btn-google {
      color: #424242;
        padding: 0;
        img {
            height: 100%;
        }
    }
    &.btn-default {
      border: 1px solid #89a7c1;
      color: #424242;
    }
    &.btn-filled {
      border: 1px solid #89a7c1;
      color: #fff;
        background-color: #89a7c1;
    }
    &.btn-primary {
      border: 1px solid #e8703b;
      background-color: #e8703b;
      color: #fff;
        &.empty {
            background-color: transparent;
            color: #e8703b;
        }
        &:hover{
            box-shadow: 0 0 2px 2px rgba(232, 112, 59,.1);
        }
    }
    &.btn-warning {
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
      background-color: #fdd835;
      text-transform: uppercase;
      color: #1e282c;
      font-weight: 500;
      &:hover {
        background-color: #ffc107;
        border-color: #985f0d;
      }
    }
    .loader {
      min-height: auto;
    }
    .processing-indicator {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }

</style>

import Vue from 'vue';
import {Vue2Storage} from 'vue2-storage'; //Библиотека работы с локальным хранилищем https://yarkovaleksei.github.io/vue2-storage/ru/
import axios from 'axios'; // https://www.npmjs.com/package/axios
import VueAxios from 'vue-axios'; // https://www.npmjs.com/package/vue-axios
import crono from 'vue-crono';//https://www.npmjs.com/package/vue-crono
import vmodal from 'vue-js-modal'; //https://www.npmjs.com/package/vue-js-modal

export default (() => {

  Vue.use(Vue2Storage, {
    prefix: 'distribune_', // Префикс хранения
    ttl: 60 * 60 * 24 * 1000 * 360 // Время хранения 360 дней
  });

  Vue.use(VueAxios, axios);
  Vue.use(crono);
  Vue.use(vmodal, { dynamic: true });

  Vue.prototype.toggleNoScroll = (noScroll)=>{ // Запрещает скролл страницы и компенсирует ширину полосы прокрутки, чтобы визуально не было дерганий в интерфейсе
    if (noScroll) {
      document.body.style.paddingRight = `${window.innerWidth - document.body.clientWidth}px`;
      document.body.classList.add('no-scroll');
      return
    }
    document.body.classList.remove('no-scroll');
    document.body.style.paddingRight = '';
  };

  Date.prototype.adjustDate = function(days){ //Добавим в Date функционал сдвига даты по дням adjustDate(1) - увеличит дату на сутки, adjustDate(-5) - уменьшит на 5
    var date;

    days = days || 0;

    if(days === 0){
      date = new Date( this.getTime() );
    } else if(days > 0) {
      date = new Date( this.getTime() );

      date.setDate(date.getDate() + days);
    } else {
      date = new Date(
        this.getFullYear(),
        this.getMonth(),
        this.getDate() - Math.abs(days),
        this.getHours(),
        this.getMinutes(),
        this.getSeconds(),
        this.getMilliseconds()
      );
    }

    this.setTime(date.getTime());

    return this;
  }
})()

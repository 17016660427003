<template>
    <div class="dropdown" v-click-outside="closeList">
        <div class="flex-between"  @click="isOpen =!isOpen">
            <span ><slot>{{getSelectedTitles()}}</slot></span>
            <div class="triangle" :class="{open : isOpen}"></div>
        </div>
        <div class="list-wrapper" v-if="isOpen">
            <div class="list">
                <div v-if="emptyItem"
                     class="list-item"
                     :class="{active: !multipleItems.length}"
                     @click.stop="clearAll">
                    {{emptyItem.selectorTitle}}
                </div>
                <div class="list-item"
                     :class="{active: checkSelected(item)}"
                     v-for="(item, index) in list"
                     :key="index"
                     @click.stop="selectItem(item)">
                    {{item.selectorTitle}}
                </div>
            </div>
            <div class="ok-btn" @click="closeList">Ok</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BaseMultipleDropdownBtn",
        props: {
            list: {
                default(){
                    return []
                }
            },
            emptyItem: null,
            selected: null,
        },
        data(){
            return {
                isOpen: false,
                multipleItems: []
            }
        },
        methods: {
            selectItem(item){
                let pos = this.getPosition(item);
                pos === -1 ? this.multipleItems.push(item) : this.multipleItems.splice(pos, 1);

                this.$emit('change', this.multipleItems)
            },
            getPosition(item){
                return this.multipleItems.findIndex((val)=>{
                    return val.selectorTitle === item.selectorTitle
                })
            },
            closeList(){
                this.isOpen = false;
            },
            checkSelected(item){
                return this.multipleItems.find((val)=>{
                    return val.selectorTitle === item.selectorTitle
                })
            },
            getSelectedTitles(){
                if (this.multipleItems.length) {
                    return this.multipleItems.map(((item)=>{
                        return item.selectorTitle
                    })).join(', ')
                } else if (this.emptyItem){
                    return this.emptyItem.selectorTitle;
                } else {
                    return ''
                }
            },
            clearAll(){
                this.multipleItems = [];
                this.isOpen = false;
                this.$emit('change', this.multipleItems);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .dropdown {
        align-items: center;
        width: 100%;
        user-select: none;
        cursor: pointer;
        position: relative;
        height: 100%;
        .flex-between {
            height: 100%;
            align-items: center;
            span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 20px;
            }
        }
    }
    .list-wrapper {
        position: absolute;
        top: 10px;
        z-index: 10;
        width: 100%;
    }

    .list-item {
        cursor: pointer;
        &.unactive {
            cursor: default;
            opacity: 0.4;
            /*pointer-events: none;*/
        }
    }

    .triangle {
        position: relative;
        &.open {
            &:after {
                bottom: -3px;
                border-top: none;
                border-bottom: 7px solid #8ba9c2;
            }
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: -12px;

            border: 7px solid transparent;
            border-top: 7px solid #8ba9c2;
        }
    }
</style>
